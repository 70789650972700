<template>
  <div>
    <!-- 搅拌桩打印版 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="statisticsTablePrint" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="9">水泥土搅拌桩施工现场原始记录表</td>
        </tr>
        <tr>
          <td colspan="9">工程名:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="5">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="4">桩机编号:{{selectItem.machineKey}}</td>
        </tr>
        <tr>
          <td colspan="9">里程号:<span>{{selectItem.pileDescribe}}</span></td>
        </tr>
        <tr>
          <td colspan="5">设计总桩长:{{selectItem.designDepth}}</td>
          <td colspan="4">水灰比:{{selectItem.waterCementRatio|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="5">水泥掺量（kg/m）:{{selectItem.designAsh|numfilters}}</td>
          <td colspan="4">桩间距(m):{{selectItem.designPilePadding}}</td>
        </tr>
        <tr>
          <td colspan="5">提钻速度（cm/min）:{{selectItem.designUpSpeed|numfilters}}</td>
          <td colspan="4">下钻速度（cm/min）:{{selectItem.designDownSpeed|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="5">桩径(cm):{{selectItem.pileWidth}}</td>
          <td colspan="4">空搅深度（m）:{{selectItem.emDepth|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="5">开始时间:{{selectItem.beginTime|datafilters}}</td>
          <td colspan="4">结束时间:{{selectItem.endTime|datafilters}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度</td>
          <td colspan="4">段浆量(L)</td>
          <td colspan="1">段灰量</td>
          <td colspan="1">段电流</td>
          <td colspan="2">速率(cm/min)</td>
        </tr>
        <tr>
          <td colspan="1">(m)</td>
          <td colspan="1">0.25</td>
          <td colspan="1">0.5</td>
          <td colspan="1">0.75</td>
          <td colspan="1">1</td>
          <td colspan="1">(kg/m)</td>
          <td colspan="1">(A)</td>
          <td colspan="1">下钻</td>
          <td colspan="1">提钻</td>
        </tr>
        <tr class="left1" v-for="item in depthReport">
          <td colspan="1">{{item+1}}</td>
          <td colspan="1">{{pulpReport[0.25+item]|numfilters}}</td>
          <td colspan="1">{{pulpReport[0.5+item]|numfilters}}</td>
          <td colspan="1">{{pulpReport[0.75+item]|numfilters}}</td>
          <td colspan="1">{{pulpReport[item+1]|numfilters}}</td>
          <td colspan="1">{{ashReport[item]|numfilters}}</td>
          <td colspan="1">{{partcurrent[item]|numfilters}}</td>
          <td colspan="1">{{downspeed[item]|numfilters}}</td>
          <td colspan="1">{{upspeed[item]|numfilters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">成桩总时间(s):</td>
          <td colspan="2">{{selectItem.pileTime}}</td>
          <td colspan="2">最大斜度:</td>
          <td colspan="2">{{selectItem.xAngle|numfilters(1)}}</td>
        </tr>
        <tr>
          <td colspan="3">往复深度1(m):</td>
          <td colspan="2">{{selectItem.frDepth|numfilters}}</td>
          <td colspan="2">往复深度2(m):</td>
          <td colspan="2">{{selectItem.reDepth|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="3">累计浆量（L）:</td>
          <td colspan="2">{{selectItem.cumulativePulp|numfilters}}</td>
          <td colspan="2">累计灰量（kg）:</td>
          <td colspan="2">{{selectItem.cumulativeAsh|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="3">桩端电流（A）:</td>
          <td colspan="2">{{selectItem.pileTopCurrent|numfilters}}</td>
          <td colspan="2">平均灰量（kg/m）:</td>
          <td colspan="2">{{selectItem.averageAsh|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="2">操作人员:</td>
          <td colspan="3"></td>
          <td colspan="2">现场监理:</td>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </table>
    <!-- 搅拌桩综合版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="statisticsTable" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="6">水泥土搅拌桩施工现场统计报表(综合版)</td>
        </tr>
        <tr>
          <td colspan="6">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="3">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="3">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="3">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="3">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="3">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="3">水灰比:<span>{{selectItem.waterCementRatio|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">水泥掺量（kg/m）<span>{{selectItem.designAsh|numfilters}}</span></td>
          <td colspan="3">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>
        </tr>
        <tr>
          <td colspan="3">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="3">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="3">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="3">空搅深度（m）<span>{{selectItem.emDepth|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="3">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度（m）</td>
          <td colspan="1">段浆量<br>（L）</td>
          <td colspan="1">段密度<br>（g/cm³）</td>
          <td colspan="1">段灰量<br>(kg/m)</td>
          <td colspan="1">段电流<br>(A)</td>
          <td colspan="1">备注</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <td colspan="1">{{item.partPulp|numfilters}}</td>
          <td colspan="1">{{item.partDensity|numfilters(3)}}</td>
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="3">最大斜度:<span>{{selectItem.xAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="3">往复深度1（m）:<span>{{selectItem.frDepth|numfilters}}</span></td>
          <td colspan="3">往复深度2（m）:<span>{{selectItem.reDepth|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td>
          <td colspan="3">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <td colspan="3">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">操作人员:</td>
          <td colspan="3">技术人员:</td>
        </tr>
        <tr>
          <td colspan="3">现场监理:</td>
          <td colspan="3">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 搅拌桩详细版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="statisticsTabledetailed" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="8">水泥土搅拌桩施工现场统计报表(详细版)</td>
        </tr>
        <tr>
          <td colspan="8">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="4">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="4">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="4">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="4">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="4">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="4">水灰比:<span>{{selectItem.waterCementRatio|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">水泥掺量（kg/m）<span>{{selectItem.designAsh|numfilters}}</span></td>
          <td colspan="4">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>
        </tr>
        <tr>
          <td colspan="4">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="4">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="4">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="4">空搅深度（m）<span>{{selectItem.emDepth|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="4">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度（m）</td>
          <td colspan="1">段浆量<br>（L）</td>
          <td colspan="1">段密度<br>（g/cm³）</td>
          <td colspan="1">段灰量<br>(kg/m)</td>
          <td colspan="1">段电流<br>(A)</td>
          <td colspan="1">段喷压<br>(MPa)</td>
          <td colspan="1">速率<br>(cm/min)</td>
          <td colspan="1">状态</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <td colspan="1">{{item.partPulp|numfilters}}</td>
          <td colspan="1">{{item.partDensity|numfilters(3)}}</td>
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1">{{item.partPressure|numfilters}}</td>
          <td colspan="1">{{item.partDownSpeed|num3filters}}</td>
          <td colspan="1">{{item.partDownSpeed|num5filters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="4">最大斜度:<span>{{selectItem.xAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="4">往复深度1（m）:<span>{{selectItem.frDepth|numfilters}}</span></td>
          <td colspan="4">往复深度2（m）:<span>{{selectItem.reDepth|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td>
          <td colspan="4">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <td colspan="4">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">操作人员:</td>
          <td colspan="4">技术人员:</td>
        </tr>
        <tr>
          <td colspan="4">现场监理:</td>
          <td colspan="4">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 搅拌桩导出 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="statisticsTableRecord" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="17">单向/双向水泥土搅拌桩施工现场记录表</td>
        </tr>
        <tr>
          <td colspan="11">施工单位:<span></span></td>
          <td colspan="1"></td>
          <td colspan="5">合同号:</td>
        </tr>
        <tr>
          <td colspan="11">监理单位:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="1"></td>
          <td colspan="5">编号: <span></span></td>
        </tr>
        <tr>
          <td colspan="3">桩机编号</td>
          <td colspan="3">机械类型</td>
          <td colspan="2">电机功率(Kw)</td>
          <td colspan="2">转速r</td>
          <td colspan="4">提升速度:1挡 2挡 3挡</td>
          <td colspan="3"> 搅拌头直径(mm)</td>
        </tr>
        <tr>
          <td colspan="4">段落编号 {{checkedData|ofiltersPipe('milePartNo')}}</td>
          <td colspan="4">水灰比</td>
          <td colspan="4">浆液比重(g/cm3)</td>
          <td colspan="5">设计桩长(m)</td>
        </tr>
        <tr>
          <td colspan="3">设计桩径(mm)</td>
          <td colspan="3">设计桩间距(m)</td>
          <td colspan="3">设计水泥用量</td>
          <td colspan="8">记录日期 {{checkedData|ofiltersPipe('startDate')}}至{{checkedData|ofiltersPipe('endDate')}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="3">序号</td>
          <td rowspan="3">排桩号</td>
          <td colspan="2">成桩时间</td>
          <td colspan="2">下钻</td>
          <td rowspan="3">第一次下钻 <br> 速度<br>cm/min</td>
          <td rowspan="3">第一次上升<br>速度<br>cm/min</td>
          <td rowspan="3">第二次下钻<br>速度<br>cm/min</td>
          <td rowspan="3">第二次上升<br>速度<br>cm/min</td>
          <td rowspan="3">压力<br>/MPa</td>
          <td rowspan="3">流量<br>L/min</td>
          <td rowspan="3">单桩喷<br>浆量(L)</td>
          <td rowspan="3">垂直度<br>(%)</td>
          <td rowspan="3">水泥浆<br>比重</td>
          <td rowspan="3">水泥用量<br>/kg</td>
          <td rowspan="3">备注</td>
        </tr>
        <tr>
          <td rowspan="2">开始时间</td>
          <td rowspan="2">结束时间</td>
          <td rowspan="2">施工总桩 <br> 长(m)</td>
          <td rowspan="2">电流(A)<br>正常/硬土</td>
        </tr>
        <tr>
        </tr>
        <tr v-for="(item,index) in checkedData" :key="index">
          <td>{{index+1}}</td>
          <td>{{item.pileNo}}</td>
          <td>{{item.beginTime|datafilter}}</td>
          <td>{{item.endTime|datafilter}}</td>
          <td>{{item.depth|numfilters}}</td>
          <td>{{item.pileTopCurrent|numfilters}}</td>
          <td>{{item.speed1}}</td>
          <td>{{item.speed3}}</td>
          <td>{{item.speed2}}</td>
          <td>{{item.speed4}}</td>
          <td>{{item.averagePressure|numfilters}}</td>
          <td>{{item.cumulativePulp/((item.endTime-item.beginTime)/60)|numfilters}}</td>
          <td>{{item.cumulativePulp|numfilters}}</td>
          <td>{{item.xAngle|numfilters}}</td>
          <td>{{item|num1filters}}</td>
          <td>{{item.cumulativeAsh|numfilters}}</td>
          <td></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">施工员:</td>
          <td colspan="5">质检员:</td>
          <td colspan="4">现场监理:</td>
          <td colspan="4">日期:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 高压旋喷桩桩综合版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="gyxpzStatisticsTable" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="6">高压旋喷桩施工现场统计报表(综合版)</td>
        </tr>
        <tr>
          <td colspan="6">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="3">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="3">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="3">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="3">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="3">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="3">水灰比:<span>{{selectItem.waterCementRatio}}</span></td>
        </tr>
        <tr>
          <td colspan="3">水泥掺量（kg/m）<span>{{selectItem.designAsh|numfilters}}</span></td>
          <td colspan="3">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>
        </tr>
        <tr>
          <td colspan="3">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="3">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="3">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="3">空搅深度（m）<span>{{selectItem.emDepth}}</span></td>
        </tr>
        <tr>
          <td colspan="3">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="3">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度（m）</td>
          <td colspan="1">段浆量<br>（L）</td>
          <td colspan="1">段密度<br>（g/cm³）</td>
          <td colspan="1">段灰量<br>(kg/m)</td>
          <td colspan="1">段电流<br>(A)</td>
          <td colspan="1">备注</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <td colspan="1">{{item.partPulp|numfilters}}</td>
          <td colspan="1">{{item.partDensity|numfilters(3)}}</td>
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="3">最大斜度:<span>{{selectItem.xAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="3">往复深度1（m）:<span>{{selectItem.frDepth|numfilters(3)}}</span></td>
          <td colspan="3">往复深度2（m）:<span>{{selectItem.reDepth|numfilters(3)}}</span></td>
        </tr>
        <tr>
          <td colspan="3">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td>
          <td colspan="3">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <td colspan="3">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">最大提速（cm/min）:<span>{{Math.abs(selectItem.maxUpSpeed)}}</span></td>
          <td colspan="3">最大钻速（cm/min）:<span>{{selectItem.maxDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="3">操作人员:</td>
          <td colspan="3">技术人员:</td>
        </tr>
        <tr>
          <td colspan="3">现场监理:</td>
          <td colspan="3">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 高压旋喷桩详细版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="gyxpzStatisticsTabledetailed" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="8">高压旋喷桩施工现场统计报表(详细版)</td>
        </tr>
        <tr>
          <td colspan="8">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="4">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="4">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="4">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="4">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="4">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="4">水灰比:<span>{{selectItem.waterCementRatio}}</span></td>
        </tr>
        <tr>
          <td colspan="4">水泥掺量（kg/m）<span>{{selectItem.designAsh|numfilters}}</span></td>
          <td colspan="4">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>
        </tr>
        <tr>
          <td colspan="4">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="4">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="4">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="4">空搅深度（m）<span>{{selectItem.emDepth}}</span></td>
        </tr>
        <tr>
          <td colspan="4">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="4">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度（m）</td>
          <td colspan="1">段浆量<br>（L）</td>
          <td colspan="1">段密度<br>（g/cm³）</td>
          <td colspan="1">段灰量<br>(kg/m)</td>
          <td colspan="1">段电流<br>(A)</td>
          <td colspan="1">段喷压<br>(MPa)</td>
          <td colspan="1">速率<br>(cm/min)</td>
          <td colspan="1">状态</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <td colspan="1">{{item.partPulp|numfilters}}</td>
          <td colspan="1">{{item.partDensity|numfilters(3)}}</td>
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1">{{item.partPressure|numfilters}}</td>
          <td colspan="1">{{item.partDownSpeed|num3filters}}</td>
          <td colspan="1">{{item.partDownSpeed|num5filters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="4">最大斜度:<span>{{selectItem.xAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="4">往复深度1（m）:<span>{{selectItem.frDepth|numfilters(3)}}</span></td>
          <td colspan="4">往复深度2（m）:<span>{{selectItem.reDepth|numfilters(3)}}</span></td>
        </tr>
        <tr>
          <td colspan="4">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td>
          <td colspan="4">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <td colspan="4">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">最大提速（cm/min）:<span>{{Math.abs(selectItem.maxUpSpeed)}}</span></td>
          <td colspan="4">最大钻速（cm/min）:<span>{{selectItem.maxDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="4">操作人员:</td>
          <td colspan="4">技术人员:</td>
        </tr>
        <tr>
          <td colspan="4">现场监理:</td>
          <td colspan="4">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 高压旋喷桩打印版 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="gyxpzStatisticsTablePrint" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="9">高压旋喷桩施工现场原始记录表</td>
        </tr>
        <tr>
          <td colspan="9">工程名:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="5">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="3">桩机编号:</td>
          <td colspan="1">{{selectItem.machineKey}}</td>
        </tr>
        <tr>
          <td colspan="9">里程号:<span>{{selectItem.pileDescribe}}</span></td>
        </tr>
        <tr>
          <td colspan="3">设计总桩长:</td>
          <td colspan="2">{{selectItem.designDepth}}</td>
          <td colspan="3">水灰比:</td>
          <td colspan="1">{{selectItem.waterCementRatio}}</td>
        </tr>
        <tr>
          <td colspan="3">水泥掺量（kg/m）:</td>
          <td colspan="2">{{selectItem.designAsh|numfilters}}</td>
          <td colspan="3">桩间距(m):</td>
          <td colspan="1">{{selectItem.designPilePadding}}</td>
        </tr>
        <tr>
          <td colspan="3">提钻速度（cm/min）:</td>
          <td colspan="2">{{selectItem.designUpSpeed}}</td>
          <td colspan="3">下钻速度（cm/min）:</td>
          <td colspan="1">{{selectItem.designDownSpeed}}</td>
        </tr>
        <tr>
          <td colspan="3">桩径（cm):</td>
          <td colspan="2">{{selectItem.pileWidth}}</td>
          <td colspan="3">空搅深度（m）:</td>
          <td colspan="1">{{selectItem.emDepth}}</td>
        </tr>
        <tr>
          <td colspan="3">开始时间:</td>
          <td colspan="2">{{selectItem.beginTime|datafilters}}</td>
          <td colspan="2">结束时间:</td>
          <td colspan="2">{{selectItem.endTime|datafilters}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度</td>
          <td colspan="4">段浆量(L)</td>
          <td colspan="1">段灰量</td>
          <td colspan="1">段电流</td>
          <td colspan="2">速率(cm/min)</td>
        </tr>
        <tr>
          <td colspan="1">(m)</td>
          <td colspan="1">0.25</td>
          <td colspan="1">0.5</td>
          <td colspan="1">0.75</td>
          <td colspan="1">1</td>
          <td colspan="1">(kg/m)</td>
          <td colspan="1">(A)</td>
          <td colspan="1">下钻</td>
          <td colspan="1">提钻</td>
        </tr>
        <tr class="left1" v-for="item in depthReport">
          <td colspan="1">{{item+1}}</td>
          <td colspan="1">{{pulpReport[0.25+item]}}</td>
          <td colspan="1">{{pulpReport[0.5+item]}}</td>
          <td colspan="1">{{pulpReport[0.75+item]}}</td>
          <td colspan="1">{{pulpReport[item+1]}}</td>
          <td colspan="1">{{ashReport[item]}}</td>
          <td colspan="1">{{partcurrent[item]}}</td>
          <td colspan="1">{{downspeed[item]}}</td>
          <td colspan="1">{{upspeed[item]}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">成桩总时间(s):</td>
          <td colspan="2">{{selectItem.pileTime}}</td>
          <td colspan="2">最大斜度:</td>
          <td colspan="2">{{selectItem.xAngle|numfilters(1)}}</td>
        </tr>
        <tr>
          <td colspan="3">往复深度1(m):</td>
          <td colspan="2">{{selectItem.frDepth|numfilters(3)}}</td>
          <td colspan="2">往复深度2(m):</td>
          <td colspan="2">{{selectItem.reDepth|numfilters(3)}}</td>
        </tr>
        <tr>
          <td colspan="3">累计浆量（L）:</td>
          <td colspan="2">{{selectItem.cumulativePulp|numfilters}}</td>
          <td colspan="2">累计灰量（kg）:</td>
          <td colspan="2">{{selectItem.cumulativeAsh|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="3">桩端电流（A）:</td>
          <td colspan="2">{{selectItem.pileTopCurrent|numfilters}}</td>
          <td colspan="2">平均灰量（kg/m）:</td>
          <td colspan="2">{{selectItem.averageAsh|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="3">最大提速（cm/min）:</td>
          <td colspan="2">{{Math.abs(selectItem.maxUpSpeed)}}</td>
          <td colspan="2">最大钻速（cm/min）:</td>
          <td colspan="2">{{selectItem.maxDownSpeed}}</td>
        </tr>
        <tr>
          <td colspan="2">操作人员:</td>
          <td colspan="3"></td>
          <td colspan="2">现场监理:</td>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </table>
    <!-- 工法桩详细版导出 -->
    <table cellspacing="0" cellpadding="0" border="1" id="GFZExportTabledetailed" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="11">施工数据记录表</td>
        </tr>
        <tr>
          <td colspan="5">工程名称:{{selectItem.projectName}}</td>
          <td colspan="6">施工单位:{{selectItem.worker}}</td>
        </tr>
        <tr>
          <td colspan="2">桩号:{{selectItem.pileDescribe}}</td>
          <td colspan="2">桩机号:{{selectItem.deviceKey}}</td>
          <td colspan="2">设计桩长:{{selectItem.designDepth}}(m)</td>
          <td colspan="2">水灰比:{{selectItem.waterCementRatio|numfilters(1)}}:1</td>
          <td colspan="3">喷浆模式:二管</td>
        </tr>
        <tr>
          <td colspan="3">开始时间:{{selectItem.beginTime|Timefilters}}</td>
          <td colspan="3">设计钻速:{{selectItem.designDownSpeed}}(cm/min)</td>
          <td colspan="3">设计提速:{{selectItem.designUpSpeed}}(cm/min)</td>
          <td colspan="2">设计浆量:{{selectItem.designPulp}}(L/min)</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1" rowspan="2">深度</td>
          <!-- <td colspan="1">桩号</td> -->
          <td colspan="1" rowspan="2">下钻速度</td>
          <td colspan="1" rowspan="2">提钻速度</td>
          <td colspan="2">管1</td>
          <td colspan="2">管2</td>
          <td colspan="2">管3</td>
          <td colspan="1" rowspan="2">段总浆量</td>
          <td colspan="1" rowspan="2">段总灰量</td>
        </tr>
        <tr>
          <td colspan="1">段流量</td>
          <td colspan="1">段浆量</td>
          <!-- <td colspan="1">段电流</td> -->
          <td colspan="1">段流量</td>
          <td colspan="1">段浆量</td>
          <!-- <td colspan="1">段电流</td> -->
          <td colspan="1">段流量</td>
          <td colspan="1">段浆量</td>
          <!-- <td colspan="1">段电流</td> -->
        </tr>
        <tr>
          <td colspan="1">m</td>
          <td colspan="1">cm/min</td>
          <td colspan="1">cm/min</td>
          <td colspan="1">L/min</td>
          <td colspan="1">L</td>
          <!-- <td colspan="1">A</td> -->
          <td colspan="1">L/min</td>
          <td colspan="1">L</td>
          <!-- <td colspan="1">A</td> -->
          <td colspan="1">L/min</td>
          <td colspan="1">L</td>
          <!-- <td colspan="1">A</td> -->
          <td colspan="1">L</td>
          <td colspan="1">kg</td>
        </tr>
        <tr v-for="(item,index) in Reportdepth" :key="index">
          <td colspan="1">{{item}}</td>
          <td colspan="1">{{downSpeedReport[index]|numsfilters}}</td>
          <td colspan="1">{{upSpeedReport[index]|numsfilters}}</td>
          <td colspan="1">{{ReportData[index][9]|numsfilters}}</td>
          <td colspan="1">{{ReportData[index][0]|numsfilters}}</td>
          <!-- <td colspan="1">{{ReportData[index][5]|numsfilters}}</td> -->
          <td colspan="1">{{ReportData[index][10]|numsfilters}}</td>
          <td colspan="1">{{ReportData[index][1]|numsfilters}}</td>
          <!-- <td colspan="1">{{ReportData[index][6]|numsfilters}}</td> -->
          <td colspan="1">{{ReportData[index][11]|numsfilters}}</td>
          <td colspan="1">{{ReportData[index][2]|numsfilters}}</td>
          <!-- <td colspan="1">{{ReportData[index][7]|numsfilters}}</td> -->
          <td colspan="1">{{ReportData[index][3]|numsfilters}}</td>
          <td colspan="1">{{ReportData[index][4]|numsfilters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">结束时间:{{selectItem.endTime|Timefilters}}</td>
          <td colspan="2">成桩时长:{{selectItem.pileTime|minFilter}}分</td>
          <td colspan="2">实际桩长:{{selectItem.depth|numfilters}}m</td>
          <td colspan="2">垂直度:{{selectItem.maxAngle|numfilters}}%</td>
          <td colspan="3">日期:{{selectItem.beginTime|DayFilter}}</td>
        </tr>
        <tr>
          <td colspan="3">管一浆量:{{selectItem.cumulativePulp1}}L</td>
          <td colspan="3">管二浆量:{{selectItem.cumulativePulp2}}L</td>
          <td colspan="3">管三浆量:{{selectItem.cumulativePulp3}}L</td>
          <td colspan="2">总浆量:{{selectItem.cumulativePulp}}L</td>
        </tr>
        <tr>
          <td colspan="3">管一灰量:{{selectItem.totalAsh1|AshFilter}}kg</td>
          <td colspan="3">管二灰量:{{selectItem.totalAsh2|AshFilter}}kg</td>
          <td colspan="3">管三灰量:{{selectItem.totalAsh3|AshFilter}}kg</td>
          <td colspan="2">总灰量:{{selectItem.cumulativeAsh}}kg</td>
        </tr>
        <tr>
          <td colspan="3">记录员:</td>
          <td colspan="4">班长:</td>
          <td colspan="4">质检员:</td>
        </tr>
      </tfoot>
    </table>
    <!-- CFG桩导出 -->
    <table cellspacing="0" cellpadding="0" border="1" id="CFGStatisticsTabledetailed" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="15">CFG桩施工报告</td>
        </tr>
        <tr>
          <td colspan="2">工程名称</td>
          <td colspan="2">{{selectAll|ofiltersPipe('ProjName')}}</td>
          <td colspan="2">施工单位</td>
          <td colspan="4">{{selectAll|ofiltersPipe('projWorker')}}</td>
          <td colspan="2">施工日期</td>
          <td colspan="3">{{selectAll|ofiltersPipe('startTime')}}至{{selectAll|ofiltersPipe('ENDTime')}}</td>
        </tr>
        <tr>
          <td colspan="2">施工数量(根)</td>
          <td colspan="2">{{selectAll|ofiltersPipe('num')}}</td>
          <td colspan="2">平均桩深(m)</td>
          <td colspan="4">{{selectAll|ofiltersPipe('averageDepth')}}</td>
          <td colspan="2">总桩深(m)</td>
          <td colspan="3">{{selectAll|ofiltersPipe('totalDepth')|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="2">平均注入量(m³)</td>
          <td colspan="2">{{selectAll|ofiltersPipe('averagePulp')}}</td>
          <td colspan="2">平均拔管速度(cm/min)</td>
          <td colspan="4">{{selectAll|ofiltersPipe('averageUpspeed')|numsfilters}}</td>
          <td colspan="2">平均持力层(比值)</td>
          <td colspan="3">{{selectAll|ofiltersPipe('averageRatio')}}</td>
        </tr>
        <tr>
          <td class="tHead" colspan="15">施工详细信息</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">序号</td>
          <!-- <td colspan="1">桩号</td> -->
          <td colspan="1">桩机编号</td>
          <td colspan="1">机械类型</td>
          <td colspan="1">桩号</td>
          <td colspan="1">北坐标</td>
          <td colspan="1">东坐标</td>
          <td colspan="1">施工深度(m)</td>
          <td colspan="1">注入量(m³)</td>
          <td colspan="1">成桩时间(min)</td>
          <td colspan="1">拔桩速度(cm/min)</td>
          <td colspan="1">成孔电流(A)</td>
          <td colspan="1">持力电流(A)</td>
          <td colspan="1">持力层(比值)</td>
          <td colspan="1">开始时间</td>
          <td colspan="1">结束时间</td>
        </tr>
        <tr v-for="(item,index) in selectAll" :key="index">
          <td colspan="1">{{index+1}}</td>
          <td colspan="1">{{item.deviceKey}}</td>
          <!-- <td colspan="1"></td> -->
          <td colspan="1">CFG</td>
          <td colspan="1"> {{item.pileDescribe}}</td>
          <td colspan="1">{{item.longitude}}</td>
          <td colspan="1">{{item.latitude}}</td>
          <td colspan="1">{{item.depth|numfilters}}</td>
          <td colspan="1">{{item.cumulativePulp|Pulpfilter}}</td>
          <td colspan="1">{{item.pileTime|minFilter}}</td>
          <td colspan="1">{{item.upSpeed|numsfilters}}</td>
          <td colspan="1">{{item.avgCurrent|numfilters}}</td>
          <td colspan="1">{{item.pileTopCurrent|numfilters}}</td>
          <td colspan="1">{{item.pileTopCurrentRatio|numfilters}}</td>
          <td colspan="1">{{item.beginTime|datafilters}}</td>
          <td colspan="1">{{item.endTime|datafilters}}</td>
        </tr>
      </tbody>
    </table>
    <!-- 粉喷桩综合版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="FPZstatisticsTable" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="6">粉喷桩施工现场统计报表(综合版)</td>
        </tr>
        <tr>
          <td colspan="6">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="3">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="3">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="3">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="3">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="3">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="3">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>

        </tr>
        <tr>
          <td colspan="3">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="3">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="3">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="3">空搅深度（m）<span>{{selectItem.emDepth}}</span></td>
        </tr>
        <tr>
          <td colspan="3">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="3">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度（m）</td>
          <td colspan="1">速度<br>(cm/min)</td>
          <td colspan="1">段灰量<br>(kg)</td>
          <td colspan="1">段电流<br>(A)</td>
          <td colspan="1">状态</td>
          <td colspan="1">备注</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <td colspan="1">{{item.partDownSpeed|numsfilters}}</td>
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1">{{item.partDownSpeed|num5filters}}</td>
          <td colspan="1"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="3">最大斜度:<span>{{selectItem.maxAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="3">往复深度1（m）:<span>{{selectItem.frDepth|numfilters(3)}}</span></td>
          <td colspan="3">往复深度2（m）:<span>{{selectItem.reDepth|numfilters(3)}}</span></td>
        </tr>
        <tr>
          <td colspan="3">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <td colspan="3">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">最大提速（cm/min）:<span>{{Math.abs(selectItem.upSpeed)|numfilters}}</span></td>
          <td colspan="3">最大钻速（cm/min）:<span>{{selectItem.downSpeed|numfilters}}</span></td>
        </tr>
        <tr>
          <!-- <td colspan="3">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td> -->
          <td colspan="6">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="3">操作人员:</td>
          <td colspan="3">技术人员:</td>
        </tr>
        <tr>
          <td colspan="3">现场监理:</td>
          <td colspan="3">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 粉喷桩详细版 -->
    <table cellspacing="0" cellpadding="0" border="1" id="FPZstatisticsTabledetailed" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="8">粉喷桩施工现场统计报表(详细版)</td>
        </tr>
        <tr>
          <td colspan="8">工程名称:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="4">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="4">桩机编号:<span>{{selectItem.machineKey}}</span></td>
        </tr>
        <tr>
          <td colspan="4">里程桩号:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="4">桩坐标:<span>{{selectItem.longitude}},{{selectItem.latitude}}</span></td>
        </tr>
        <tr>
          <td colspan="4">设计总桩长(m):<span>{{selectItem.designDepth}}</span></td>
          <td colspan="4">桩间距（m）<span>{{selectItem.designPilePadding}}</span></td>
        </tr>
        <tr>
          <td colspan="4">提钻速度（cm/min）<span>{{selectItem.designUpSpeed}}</span></td>
          <td colspan="4">下钻速度（cm/min）<span>{{selectItem.designDownSpeed}}</span></td>
        </tr>
        <tr>
          <td colspan="4">桩径（cm）<span>{{selectItem.pileWidth}}</span></td>
          <td colspan="4">空搅深度（m）<span>{{selectItem.emDepth}}</span></td>
        </tr>
        <tr>
          <td colspan="4">开始时间: <span>{{selectItem.beginTime|datafilters}}</span></td>
          <td colspan="4">结束时间:<span>{{selectItem.endTime|datafilters}}</span></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度(m)</td>
          <!-- <td colspan="1">段浆量<br>（L/m）</td> -->
          <!-- <td colspan="1">段密度<br>（g/cm³）</td> -->
          <td colspan="1">段灰量(kg/m)</td>
          <td colspan="1">段电流(A)</td>
          <td colspan="1">段喷压(MPa)</td>
          <td colspan="1">速度(cm/min)</td>
          <td colspan="1">段时长(s)</td>
          <td colspan="2">状态</td>
        </tr>
        <tr v-for="(item,index) in selectItem.data" :key="index">
          <td colspan="1">{{item.partDeep}}</td>
          <!-- <td colspan="1">{{item.partPulp|numfilters}}</td> -->
          <!-- <td colspan="1">{{item.partDensity|numfilters(3)}}</td> -->
          <td colspan="1">{{item.partAsh|numfilters}}</td>
          <td colspan="1">{{item.partCurrent|numfilters(1)}}</td>
          <td colspan="1">{{item.partPressure|numfilters}}</td>
          <td colspan="1">{{item.partDownSpeed|num3filters}}</td>
          <td colspan="1">{{item.partTime}}</td>
          <td colspan="2">{{item.partDownSpeed|num5filters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">成桩总时间（s）:<span>{{selectItem.pileTime}}</span></td>
          <td colspan="4">最大斜度:<span>{{selectItem.maxAngle|numfilters(1)}}</span></td>
        </tr>
        <tr>
          <td colspan="4">往复深度1（m）:<span>{{selectItem.frDepth|numfilters(3)}}</span></td>
          <td colspan="4">往复深度2（m）:<span>{{selectItem.reDepth|numfilters(3)}}</span></td>
        </tr>
        <tr>
          <td colspan="4">最大电流（A）<span>{{selectItem.maxCurrent|numfilters}}</span></td>
          <!-- <td colspan="4">累计浆量（L）:<span>{{selectItem.cumulativePulp|numfilters}}</span></td> -->
          <td colspan="4">累计灰量（kg）:<span>{{selectItem.cumulativeAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">最大提速（cm/min）:<span>{{Math.abs(selectItem.upSpeed)|numfilters}}</span></td>
          <td colspan="4">平均灰量（kg/m）<span>{{selectItem.averageAsh|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="8">最大钻速（cm/min）:<span>{{selectItem.downSpeed|numfilters}}</span></td>
        </tr>
        <tr>
          <td colspan="4">操作人员:</td>
          <td colspan="4">技术人员:</td>
        </tr>
        <tr>
          <td colspan="4">现场监理:</td>
          <td colspan="4">打印时间:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 粉喷桩打印版 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="FPZstatisticsTablePrint" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="9">粉喷桩施工现场原始记录表</td>
        </tr>
        <tr>
          <td colspan="9">工程名:<span>{{selectItem.projectName}}</span></td>
        </tr>
        <tr>
          <td colspan="5">施工单位:<span>{{selectItem.worker}}</span></td>
          <td colspan="4">桩机编号:{{selectItem.machineKey}}</td>
        </tr>
        <tr>
          <td colspan="9">里程号:<span>{{selectItem.pileDescribe}}</span></td>
        </tr>
        <tr>
          <td colspan="5">设计总桩长:{{selectItem.designDepth}}</td>
          <td colspan="4">桩间距(m):{{selectItem.designPilePadding}}</td>

        </tr>
        <!-- <tr>
          <td colspan="5">水泥掺量（kg/m）:{{selectItem.designAsh|numfilters}}</td>
          <td colspan="4">水灰比:{{selectItem.waterCementRatio}}</td>
        </tr> -->
        <tr>
          <td colspan="5">提钻速度（cm/min）:{{selectItem.designUpSpeed}}</td>
          <td colspan="4">下钻速度（cm/min）:{{selectItem.designDownSpeed}}</td>
        </tr>
        <tr>
          <td colspan="5">桩径（cm）:{{selectItem.pileWidth}}</td>
          <td colspan="4">空搅深度（m）:{{selectItem.emDepth}}</td>
        </tr>
        <tr>
          <td colspan="5">开始时间:{{selectItem.beginTime|datafilters}}</td>
          <td colspan="4">结束时间:{{selectItem.endTime|datafilters}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1">深度</td>
          <td colspan="2">段灰量</td>
          <td colspan="2">段电流</td>
          <td colspan="4">速率(cm/min)</td>
        </tr>
        <tr>
          <td colspan="1">(m)</td>
          <td colspan="2">(kg/m)</td>
          <td colspan="2">(A)</td>
          <td colspan="2">下钻</td>
          <td colspan="2">提钻</td>
        </tr>
        <tr class="left1" v-for="item in depthReport">
          <td colspan="1">{{item+1}}</td>
          <td colspan="2">{{ashReport[item]}}</td>
          <td colspan="2">{{partcurrent[item]}}</td>
          <td colspan="2">{{downspeed[item]}}</td>
          <td colspan="2">{{upspeed[item]|upSpeedfilters}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5">成桩总时间(s):{{selectItem.pileTime}}</td>
          <td colspan="4">最大斜度:{{selectItem.maxAngle|numfilters(1)}}</td>
        </tr>
        <tr>
          <td colspan="5">往复深度1(m):{{selectItem.frDepth|numfilters(3)}}</td>
          <td colspan="4">往复深度2(m):{{selectItem.reDepth|numfilters(3)}}</td>
        </tr>
        <tr>
          <td colspan="5">平均灰量（kg/m）:{{selectItem.averageAsh|numfilters}}</td>
          <td colspan="4">累计灰量（kg）:{{selectItem.cumulativeAsh|numfilters}}</td>
        </tr>

        <tr>
          <td colspan="5">最大提速（cm/min）:{{Math.abs(selectItem.upSpeed)|numfilters}}</td>
          <td colspan="4">最大钻速（cm/min）:{{selectItem.downSpeed|numfilters}}</td>
        </tr>
        <tr>
          <td colspan="9">桩端电流（A）:{{selectItem.pileTopCurrent|numfilters}}</td>

        </tr>
        <tr>
          <td colspan="2">操作人员:</td>
          <td colspan="3"></td>
          <td colspan="2">现场监理:</td>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </table>
    <!-- 粉喷桩导出 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="FPZstatisticsTableRecord" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="14">粉喷桩施工现场记录表</td>
        </tr>
        <tr>
          <td colspan="7">施工单位:<span></span></td>
          <td colspan="7">合同号:</td>
        </tr>
        <tr>
          <td colspan="7">监理单位:<span>{{selectItem.pileDescribe}}</span></td>
          <td colspan="7">编号: <span></span></td>
        </tr>
        <tr>
          <td colspan="3">桩机编号</td>
          <td colspan="2">机械类型</td>
          <td colspan="2">电机功率(Kw)</td>
          <td colspan="2">转速r</td>
          <td colspan="3">提升速度:1挡 2挡 3挡</td>
          <td colspan="2"> 搅拌头直径(mm)</td>
        </tr>
        <tr>
          <td colspan="7">段落编号 K1+100_K1+500</td>
          <td colspan="7">设计桩长(m)</td>
        </tr>
        <tr>
          <td colspan="3">设计桩径mm</td>
          <td colspan="3">设计桩间距(m)</td>
          <td colspan="3">设计水泥用量</td>
          <td colspan="5">记录日期 {{checkedData|ofiltersPipe('startTime')}}至{{checkedData|ofiltersPipe('ENDTime')}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="3">序号</td>
          <td rowspan="3">桩号</td>
          <td colspan="2">成桩时间</td>
          <td colspan="2">下钻</td>
          <td rowspan="3" colspan="2">平均下钻 <br> 速度<br>cm/min</td>
          <td rowspan="3" colspan="2">平均提钻<br>速度<br>cm/min</td>
          <td rowspan="3">压力/MPa</td>
          <td rowspan="3">垂直度<br>(%)</td>
          <td rowspan="3">水泥用量<br>/kg</td>
          <td rowspan="3">备注</td>
        </tr>
        <tr>
          <td rowspan="2">开始时间</td>
          <td rowspan="2">结束时间</td>
          <td rowspan="2">施工总桩 <br> 长(m)</td>
          <td rowspan="2">电流(A)<br>正常/硬土</td>
        </tr>
        <tr>
        </tr>
        <tr v-for="(item,index) in checkedData" :key="index">
          <td>{{index+1}}</td>
          <td>{{item.pileDescribe}}</td>
          <td>{{item.beginTime|datafilter}}</td>
          <td>{{item.endTime|datafilter}}</td>
          <td>{{item.depth|numfilters}}</td>
          <td>{{item.maxCurrent|numfilters}}</td>
          <td colspan="2">{{item.speed1}}</td>
          <td colspan="2">{{item.speed2}}</td>
          <td>{{item.averagePressure|numfilters}}</td>
          <td>{{item.maxAngle|numfilters}}</td>
          <td>{{item.cumulativeAsh|numfilters}}</td>
          <td></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">施工员:</td>
          <td colspan="4">质检员:</td>
          <td colspan="3">现场监理:</td>
          <td colspan="4">日期:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 振冲砂桩报表导出 -->
    <table class="excel" cellspacing="0" cellpadding="0" border="1" id="SPReports" v-show="false">
      <thead>
        <tr>
          <td colspan="9">工程名称：</td>
        </tr>
        <tr>
          <td colspan="4">施工单位：</td>
          <td colspan="5">振冲器型号：</td>
        </tr>
        <tr>
          <td colspan="4">设计桩长L(m)：</td>
          <td colspan="5">设计桩径D(m)：</td>
        </tr>
        <tr>
          <td colspan="4">试成桩确定的振密电流(A)：</td>
          <td colspan="5">桩号编号：{{selectItem.pileDescribe}}</td>
        </tr>
        <tr>
          <td colspan="4">试成桩确定的留振时间(s)：</td>
          <td colspan="5">施工日期：{{selectItem.beginTime|DayFilter}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="2" colspan="1">深度<br>(m)</td>
          <td colspan="3">成孔</td>
          <td colspan="5">成桩</td>
        </tr>
        <tr>
          <td colspan="1">作业时长<br>(s)</td>
          <td colspan="1">成孔电流<br>(A)</td>
          <td colspan="1">水压<br>(MPa)</td>
          <td colspan="1">作业时长<br>(s)</td>
          <td colspan="1">填料量<br>(kg)</td>
          <td colspan="1">振密电流<br>(A)</td>
          <td colspan="1">留振时间<br>(s)</td>
          <td colspan="1">振密时长<br>(s)</td>
          <!-- <td colspan="1">水压(MPa)</td> -->
        </tr>
        <tr v-for="(item,index) in ReportDepthSP" :key="index">
          <td colspan="1">{{item|numfilters(1)}}</td>
          <td colspan="1">{{CKtime[index]}}</td>
          <td colspan="1">{{CKcurrent[index]}}</td>
          <td colspan="1">{{CKPressure[index]}}</td>
          <td colspan="1">{{CZtime[index]}}</td>
          <td colspan="1">{{CZweight[index]|numfilters(0)}}</td>
          <td colspan="1">{{CZcurrent[index]}}</td>
          <td colspan="1">8</td>
          <td colspan="1">{{CZZMtime[index]|filterZM}}</td>
          <!-- <td colspan="1">{{CZPressure[index]}}</td> -->
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">技术负责人：</td>
          <td colspan="3">审核：</td>
          <td colspan="3">施工班组：</td>
        </tr>
      </tfoot>
    </table>
    <!-- 高压旋喷桩桩导出 -->
    <table cellspacing="0" cellpadding="0" border="1" id="GYXPZstatisticsTableRecord" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="16">高压旋喷桩施工现场记录表</td>
        </tr>
        <tr>
          <td colspan="10">施工单位:</td>
          <td colspan="6">合同号:</td>
        </tr>
        <tr>
          <td colspan="10">监理单位:</td>
          <td colspan="6">编号: </td>
        </tr>
        <tr>
          <td colspan="4">桩机编号:{{GYXPZreportPile}}</td>
          <td colspan="4">转速r:</td>
          <td colspan="8">工艺类型:单管 双重管 三重管</td>
        </tr>
        <tr>
          <td colspan="4">水灰比:</td>
          <td colspan="4">浆液比重(g/cm3):</td>
          <td colspan="4">设计桩长(m):</td>
          <td colspan="4"> 设计水泥掺量(%):</td>
        </tr>
        <tr>
          <td colspan="4">设计桩径(mm):</td>
          <td colspan="4">设计桩间距(m):</td>
          <td colspan="8">记录日期 {{GYXPZbegintime}}至{{GYXPZendtime}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="3">序号</td>
          <td rowspan="3">桩号</td>
          <td colspan="2">成桩时间</td>
          <td colspan="2">桩身</td>
          <td colspan="2">钻机</td>
          <td colspan="2">注浆机</td>
          <td rowspan="3">水量<br>L</td>
          <td rowspan="3">气量<br>m³</td>
          <td rowspan="3">累计浆量<br>L</td>
          <td rowspan="3">水泥浆<br>比重</td>
          <td rowspan="3">累计灰量<br>kg</td>
          <td rowspan="3">备注</td>
        </tr>
        <tr>
          <td rowspan="2">开始时间</td>
          <td rowspan="2">结束时间</td>
          <td rowspan="2">桩长(m)</td>
          <td rowspan="2">垂直度(%)</td>
          <td rowspan="2">下钻速度<br>cm/min</td>
          <td rowspan="2">提钻速度<br>cm/min</td>
          <td rowspan="2">压力<br>Mpa</td>
          <td rowspan="2">流量<br>L/min</td>
        </tr>
        <tr>
        </tr>
        <tr v-for="(item,index) in GYXPreport" :key="index">
          <td>{{index+1}}</td>
          <td>{{item.pileDescribe}}</td>
          <td>{{item.beginTime|datafilter}}</td>
          <td>{{item.endTime|datafilter}}</td>
          <td>{{item.pileDepth|numfilters}}</td>
          <td>{{item.maxAngle|numfilters}}</td>
          <td>{{item.downSpeed|numfilters}}</td>
          <td>{{item.upSpeed|numfilters}}</td>
          <td>{{item.maxPressure|numfilters}}</td>
          <td>{{item.flow}}</td>
          <td>{{item.cumulativeWater|numfilters}}</td>
          <td>{{item.cumulativeAir|numfilters}}</td>
          <td>{{item.cumulativePulp|numfilters}}</td>
          <td>{{item.WaterPulpRatio}}</td>
          <td>{{item.cumulativeAsh|numfilters}}</td>
          <td></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">施工员:</td>
          <td colspan="4">质检员:</td>
          <td colspan="4">现场监理:</td>
          <td colspan="4">日期:</td>
        </tr>
      </tfoot>
    </table>
    <!-- 工法桩导出 -->
    <table cellspacing="0" cellpadding="0" border="1" id="GFZstatisticsTableRecord" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="18">SMW工法桩施工现场记录表</td>
        </tr>
        <tr>
          <td colspan="9">施工单位:</td>
          <td colspan="9">合同号:</td>
        </tr>
        <tr>
          <td colspan="9">监理单位:</td>
          <td colspan="9">编号: </td>
        </tr>
        <tr>
          <td colspan="9">机械类型:</td>
          <td colspan="9">桩机编号:{{repdevice}}</td>
        </tr>
        <tr>
          <td colspan="4">电机功率(Kw):</td>
          <td colspan="5">钻杆数量:</td>
          <td colspan="4">搅拌头直径(mm):</td>
          <td colspan="5">截面积:</td>
        </tr>
        <tr>
          <td colspan="3">水灰比:{{waterCementRatio|numfilters}}</td>
          <td colspan="3">浆液比重(g/cm3):</td>
          <td colspan="4"> 设计水泥用量:</td>
          <td colspan="8">记录日期:{{dayTimeStart}}至{{dayTimeEnd}}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="1" rowspan="3">序号</td>
          <td colspan="1" rowspan="3">桩号</td>
          <td colspan="3">成桩时间</td>
          <td colspan="1">设计</td>
          <td colspan="1">设计</td>
          <td colspan="1">设计</td>
          <td colspan="2">钻进</td>
          <td colspan="1">下钻</td>
          <td colspan="1">提升</td>
          <td colspan="1">压力</td>
          <td colspan="1">平均浆量</td>
          <td colspan="1">累计浆量</td>
          <td colspan="1">垂直度</td>
          <td colspan="1">水泥用量</td>
          <td colspan="1" rowspan="3">备注</td>
        </tr>
        <tr>
          <td colspan="1" rowspan="2">日期</td>
          <td colspan="1" rowspan="2">开始时间</td>
          <td colspan="1" rowspan="2">结束时间</td>
          <td colspan="1">桩长</td>
          <td colspan="1">下钻速度</td>
          <td colspan="1">提钻速度</td>
          <td colspan="1">施工总桩</td>
          <td colspan="1">电流(A)</td>
          <td colspan="1">速度</td>
          <td colspan="1">速度</td>
          <td colspan="1" rowspan="2">MPa</td>
          <td colspan="1" rowspan="2">L/m</td>
          <td colspan="1" rowspan="2">L</td>
          <td colspan="1" rowspan="2">%</td>
          <td colspan="1" rowspan="2">kg</td>
        </tr>
        <tr>
          <td colspan="1">(m)</td>
          <td colspan="1">cm/min</td>
          <td colspan="1">cm/min</td>
          <td colspan="1">长(m)</td>
          <td colspan="1">正常/硬土</td>
          <td colspan="1">cm/min</td>
          <td colspan="1">cm/min</td>
        </tr>
        <tr v-for="(item,index) in GFZreportData" :key="index">
          <td colspan="1">{{index+1}}</td>
          <td colspan="1">{{item.pileDescribe}}</td>
          <td colspan="1">{{item.beginTime|DayFilter}}</td>
          <td colspan="1">{{item.beginTime|Timefilters}}</td>
          <td colspan="1">{{item.endTime|Timefilters}}</td>
          <td colspan="1">{{item.designDepth|designdepth}}</td>
          <td colspan="1">{{item.designDownSpeed|designdepth}}</td>
          <td colspan="1">{{item.designUpSpeed|designdepth}}</td>
          <td colspan="1">{{item.depth|numfilters}}</td>
          <td colspan="1">{{item.reportCurrent|AshFilter}}</td>
          <td colspan="1">{{item.downSpeed|numfilters}}</td>
          <td colspan="1">{{item.upSpeed|num3filters}}</td>
          <td colspan="1">{{item.reportPressure|AshFilter}}</td>
          <td colspan="1">{{item.averagePulp|numfilters}}</td>
          <td colspan="1">{{item.cumulativePulp|numfilters}}</td>
          <td colspan="1">{{item.maxAngle|numfilters(1)}}</td>
          <td colspan="1">{{item.cumulativeAsh|numfilters}}</td>
          <td colspan="1"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6">施工员:</td>
          <td colspan="6">质检员:</td>
          <td colspan="6">日期:</td>
        </tr>
      </tfoot>
    </table>
    <!-- RTK成槽导出历史 -->
    <table cellspacing="0" cellpadding="0" border="1" id="ARDreporthistory" v-show="false">
      <tbody>
        <tr>
          <td>作业序号</td>
          <td>设备名称</td>
          <td>设备编号</td>
          <td>开始时间</td>
          <td>结束时间</td>
          <td>成槽时间(min)</td>
          <td>设计深度(m)</td>
          <td>成槽深度(m)</td>
          <td>横移距离(m)</td>
          <td>成槽面积(m²)</td>
          <td>桩顶高程(m)</td>
          <td>桩底高程(m)</td>
          <td>抓挖次数(次)</td>
        </tr>
        <tr v-for="(item,index) in ARDreportHistory" :key="index">
          <td>{{item.pileDescribe}}</td>
          <td>{{item.deviceName}}</td>
          <td>{{item.deviceKey}}</td>
          <td>{{item.beginTime|timefilters}}</td>
          <td>{{item.endTime|timefilters}}</td>
          <td>{{item.pileTime|minFilter}}</td>
          <td>{{item.designPileLength|numfilters}}</td>
          <td :style="{'color': item.color}">{{item.maxDepth|numfilters}}</td>
          <td>{{item.workWidth|numsfilters}}</td>
          <td>{{item.pileSquarem2|numsfilters}}</td>
          <td>{{item.pileTopelevation|numsfilters}}</td>
          <td>{{item.pileBottomElevation|numsfilters}}</td>
          <td>{{item.rotationNum}}</td>
        </tr>
      </tbody>
    </table>
    <!-- RTK成槽导出预警 -->
    <table cellspacing="0" cellpadding="0" border="1" id="ARDreportdata" v-show="false">
      <tbody>
        <tr>
          <td>作业序号</td>
          <td>设备名称</td>
          <td>设备编号</td>
          <td>开始时间</td>
          <td>结束时间</td>
          <td>设计深度(m)</td>
          <td>成槽深度(m)</td>
          <td>横移距离(m)</td>
          <td>预警原因</td>
        </tr>
        <tr v-for="(item,index) in ARDreport" :key="index">
          <td>{{item.pileDescribe}}</td>
          <td>{{item.devName}}</td>
          <td>{{item.deviceKey}}</td>
          <td>{{item.beginTime|timefilters}}</td>
          <td>{{item.endTime|timefilters}}</td>
          <td>{{item.designPileLength|numfilters}}</td>
          <td style="color:#ff3333">{{item.maxDepth|numfilters}}</td>
          <td>{{item.workWidth|numsfilters}}</td>
          <td>{{item.warnreason}}</td>
        </tr>
      </tbody>
    </table>
    <!-- 粉喷桩预警页面导出  warningFPZReport-->
    <table cellspacing='0' cellpadding='0' border='1' id="warningFPZReport" v-show="false">
      <thead>
        <tr>
          <td class="tHead" colspan="13">粉喷桩预警信息记录表</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>序号</td>
          <td>设备名称</td>
          <td colspan="2">桩号</td>
          <td colspan="3">预警原因(监测/阈值)</td>
          <td>开始时间</td>
          <td>结束时间</td>
          <td>施工桩长(m)</td>
          <td>累计灰量(kg)</td>
          <td>最大斜度(%)</td>
          <td>成桩时间(min)</td>
        </tr>
        <tr v-for="(item,index) in warningFPZ" :key="index">
          <td>{{index+1}}</td>
          <td>{{item.devName}}</td>
          <td colspan="2">{{item.pileDescribe}}</td>
          <td colspan="3">{{item.warnreason}}</td>
          <td>{{item.beginTime|datafilters}}</td>
          <td>{{item.endTime|datafilters}}</td>
          <td>{{item.depth|numfilters}}</td>
          <td>{{item.cumulativeAsh|numfilters(1)}}</td>
          <td>{{item.maxAngle|numfilters(1)}}</td>
          <td>{{item.pileTime|workingTime}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">施工员:</td>
          <td colspan="3">质检员:</td>
          <td colspan="3">现场监理:</td>
          <td colspan="4">日期:</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ['selectAll', 'selectItem', 'checkedData', 'depthReport', 'pulpReport', 'ashReport', 'upspeed', 'downspeed', 'partcurrent', 'Reportdepth',
    'ReportDepthSP', 'CKtime', 'CKcurrent', 'CKPressure', 'CZtime', 'CZweight', 'CZcurrent', 'CZLZtime', 'CZZMtime', 'CZPressure', 'GYXPreport',
    'GYXPZreportPile', 'GYXPZbegintime', 'GYXPZendtime', 'ReportData', 'downSpeedReport', 'upSpeedReport', 'dayTimeStart', 'dayTimeEnd', 'waterCementRatio',
    'repdevice', 'GFZreportData', 'ARDreportHistory', 'ARDreport', 'warningFPZ'],
  data() {
    return {
    }
  },
  filters: {
    workingTime(val) {
      return parseFloat(val / 60).toFixed(2)
    },
    filterZM(val) {
      if (val == 0) {
        return val
      } else {
        return val = val + ((val + 1) % 4)
      }
    },
    AshFilter(val) {
      if (val == 'NaN') {
        return 0
      } else {
        return val
      }
    },
    upSpeedfilters(val) {
      if (val == undefined) {
        return 0
      } else {
        return val
      }
    },
    designdepth(val) {
      if (val == undefined) {
        return '-'
      } else {
        return parseFloat(val).toFixed(2)
      }
    },
    ofiltersPipe(val, val2) {
      if (!val) {
        return ''
      } else {
        if (val[val2] == null) {
          return ''
        } else {
          return val[val2]
        }
      }
    },
    timefilters(val) {
      return moment(val).format('YYYY/MM/DD HH:mm')
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    datafilter(val) {
      return moment(val * 1000).format('HH:mm')
    },
    Timefilters(val) {
      return moment(val * 1000).format('HH:mm:ss')
    },
    DayFilter(val) {
      return moment(val * 1000).format('YYYY-MM-DD')
    },
    minFilter(val) {
      return parseInt(val / 60)
    },
    numsfilters(val) {
      if (val == undefined) {
        return 0
      } else {
        return (Math.abs(val)).toFixed(2)
      }
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    num5filters(val) {
      if (val == 0) {
        return '停钻'
      }
      if (val > 0) {
        return '下钻'
      }
      if (val < 0) {
        return '提钻'
      }
    },
    num3filters(val) {
      return Math.abs(parseFloat(val).toFixed(2))
    },
    num1filters(val) {
      if (val.cumulativePulp == 0) {
        return 0
      } else {
        return (1 + (2 * val.cumulativeAsh) / (3 * val.cumulativePulp)).toFixed(3)
      }
    },
    Pulpfilter(val) {
      return (parseFloat(val) / 1000).toFixed(2)
    }
  },
  mounted() {
    // console.log(this.Reportdepth);
  }
}
</script>
<style scoped>
</style>